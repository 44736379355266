import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { MainpageComponent } from './mainpage/mainpage.component';
import { OfferComponent } from './offer/offer.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactComponent } from './contact/contact.component';

const appRoutes: Routes = [
  {
    path: 'glowna',
    component: MainpageComponent
  },
  {
    path: 'oferta',
    component: OfferComponent
  },
  {
    path: 'galeria',
    component: GalleryComponent
  },
  {
    path: 'kontakt',
    component: ContactComponent
  },
  {
    path: '',
    redirectTo: 'glowna',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'glowna',
    pathMatch: 'full'
  },
];

@NgModule({
  // imports: [RouterModule.forRoot(appRoutes)],
  imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRouting { }
