import { Component, OnInit } from '@angular/core';
import { MainService } from '../main.service';
import { ViewChild, ElementRef } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  @Output() offerHandler: EventEmitter<any> = new EventEmitter();
  @ViewChild('offerAppear') offerAppear: ElementRef;
  title: string;
  offers: Array<any>;
  interactiveOffers: boolean;

  constructor(public mainService: MainService) { }

  ngOnInit() {
    this.offerHandler.emit(this.offerAppear);
    this.sendMessage();
    this.title = this.mainService.description.offer.title;
    this.offers = this.mainService.description.offer.offers;
    this.interactiveOffers = this.mainService.description.offer.interactiveOffers;
  }
  sendMessage(): void {     // send message to subscribers via observable subject
    this.mainService.sendVariable(this.offerAppear);
  }

  expand(event) {
    if (this.interactiveOffers) {
      event.target.parentElement.parentElement.classList.toggle('hidden');
      event.target.classList.toggle('active');
    }
  }
}
