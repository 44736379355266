import { Injectable } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
const posts = require('../assets/options/posts.json');
const descriptions = require('../assets/options/description.json');
declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class MainService {

  isGlowna: boolean;
  wasGlowna: boolean;
  posts: Array<Posts> = posts;
  pathOfPhoto = 'assets/slides/';
  description: Description = descriptions;

  subject = new Subject<any>();

  constructor(router: Router) {
    this.addPathToPhoto(this.pathOfPhoto);
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) { // you were there
        this.wasGlowna = this.checkPath('glowna');
      }
      if (event instanceof NavigationEnd) { // you will there in your click
        this.isGlowna = this.checkPath('glowna');
      }
    });
  }

  checkPath(path) {
    const piecesPath = window.location.href.split('/');
    const lastSegment = piecesPath[piecesPath.length - 1];
    if (lastSegment === path) {
      return true;
    } else {
      return false;
    }
  }

  sendVariable(variable: any) {
    this.subject.next(variable);
  }
  getVariable(): Observable<any> {
    return this.subject.asObservable();
  }

  addPathToPhoto(path) {
    this.posts.forEach((slider) => {
      slider.sliders.forEach((slide) => {
        slide.photo = path + slide.photo;
        slide.photoSmall = path + slide.photoSmall;
      });
    });
  }
}

interface Posts {
  isActive: boolean;
  isAutoPlay: boolean;
  type: number;
  title: string;
  sliders: Array<Post>;
}

interface Post {
  photo: string;
  photoSmall: string;
  desctription?: string;
}

interface Description {
  mainPage: string;
  offer: Offer;
  contact: Contact;
}

interface Offer {
  title: string;
  interactiveOffers: boolean;
  offers: Array<Asortment>;
}

interface Asortment {
  assortment: string;
  description: string;
}

interface Contact {
  location: string;
  telephone: string;
  mail: string;
  facebook: string;
  facebookAddress: string;
}
