import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from 'ngx-gallery';
import 'hammerjs';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  isOpenPreview = false;
  @Input() post;
  title: string;

  @Output()
  previewTask = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    this.title = this.post.title;
    this.options(this.post.type);
    this.images(this.post.type);

  }

  options(type) {
    this.galleryOptions = [
      {
        width: '900px',
        height: '600px',
        thumbnails: type ? true : false,
        imageArrows: type ? true : false,
        imageArrowsAutoHide: type ? true : false,
        previewInfinityMove: type ? true : false,
        imageAutoPlay: this.post.isAutoPlay ? true : false,
        thumbnailsArrowsAutoHide: true,
        imageAutoPlayPauseOnHover: true,
        previewAutoPlay: false,
        previewAutoPlayPauseOnHover: true,
        previewCloseOnClick: true,
        previewCloseOnEsc: true,
        thumbnailsMoveSize: 4,
        imageDescription: true,
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      {
        breakpoint: 1599,
        width: '600px',
        height: '400px',
      },
      {
        breakpoint: 768,
        width: 'calc(100vw - 87px)',
        height: 'calc(100vh - 140px)',
        imageAutoPlay: false,
        imageSwipe: true,
        imageArrowsAutoHide: false,
        thumbnailsArrowsAutoHide: false,
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 4
      },
      {
        breakpoint: 576,
        imageArrows: false,
        thumbnailsSwipe: true,
        thumbnailsArrows: false,
        thumbnailsRemainingCount: true,
      }
    ];
  }

  images(type) {
    this.galleryImages = [];

    if (type === 0) {
      this.galleryImages.push({
        small: this.post.sliders[0].photoSmall,
        medium: this.post.sliders[0].photo,
        big: this.post.sliders[0].photo,
        description: this.post.sliders[0].desctription,
      });
    } else if (type === 1) {
      this.post.sliders.forEach((element) => {
        this.galleryImages.push({
          small: element.photoSmall,
          medium: element.photo,
          big: element.photo,
          description: this.post.sliders[0].desctription,
        });
      });
    } else if (type === 2) {
      this.post.sliders.forEach((element) => {
        this.galleryImages.push({
          small: element.photoSmall,
          medium: element.photo,
          big: element.photo,
          description: element.desctription,
        });
      });
    }
  }

  previewOpen() {
    this.isOpenPreview = true;
    this.previewTask.emit(this.isOpenPreview);
  }

  previewClose() {
    this.isOpenPreview = false;
    this.previewTask.emit(this.isOpenPreview);
  }

  ngOnDestroy() {
    this.galleryOptions = null;
  }
}
