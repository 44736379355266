import { Component, OnInit } from '@angular/core';
import { MainService } from '../main.service';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.scss']
})
export class MainpageComponent implements OnInit {

  slogan: string;

  constructor(public mainService: MainService) { }

  ngOnInit() {
    this.slogan = this.mainService.description.mainPage;
  }
}
