import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import $js from 'jquery-scrollify';
import { MainService } from '../main.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, AfterViewInit {

  section: any;
  sections: any;
  posts: any;
  myText: string;
  isOpenPreview = false;

  constructor(public mainService: MainService) { }

  ngOnInit() {
    this.posts = this.mainService.posts;
  }

  ngAfterViewInit() {
    this.section = $('.section');
    this.sections = Array.from(this.section);

    $js(function () {
      $js.scrollify({
        section: 'section',
      });
    });

    this.scrollDetect();
  }

  scrollDetect() {
    $(window).scroll(() => { // rozpoznaje sekcje i daje kropke
      this.sections.forEach((element, index) => {
        if (element.offsetTop === $(window).scrollTop()) {
          this.sliderIsActive(index);
          $('.gallery__menu__radio')[index].checked = true;
        }
      });
    });
  }

  sliderIsActive(indexLoop1) { // uaktywnia slider do inicjalizacji
    this.sections.forEach((elementLoop2, indexLoop2) => {
      this.posts[indexLoop2].isActive = false;
    });
    this.posts[indexLoop1].isActive = true;
  }

  goToSection(i) { // po kliknieciu skacze do kolejnej sekcji
    const scrolling = $(window).height() * i;
    if ($(window).scrollTop() === scrolling) {
      return;
    }
    $('html, body').animate({
      scrollTop: scrolling
    }, 500);
  }

  previewPhoto(sliderTask: boolean): void {
    this.isOpenPreview = sliderTask;
  }
}
