import { Component, OnInit } from '@angular/core';
import { MainService } from '../main.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  address: string;
  phone: string;
  mail: string;
  facebook: string;
  facebookAddress: string;
  contactPath = this.mainService.description.contact;

  constructor(public mainService: MainService) { }

  ngOnInit() {
    this.address = this.contactPath.location;
    this.phone = this.contactPath.telephone;
    this.mail = this.contactPath.mail;
    this.facebook = this.contactPath.facebook;
    this.facebookAddress = this.contactPath.facebookAddress;
  }

  callMe() {
    parent.location.href = 'tel:' + this.phone;
  }
  sendMe() {
    parent.location.href = 'mailto:' + this.mail;
  }
}
