import { Component, OnInit } from '@angular/core';
import { MainService } from '../main.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  message: any;
  subscription: Subscription;

  constructor(public mainService: MainService) {
    this.subscription = this.mainService.getVariable().subscribe(message => {
      this.message = message;
      this.removeOfferAppearClass(this.message);
    });
  }

  ngOnInit() {
  }

  removeOfferAppearClass(handler) {
    if (this.mainService.wasGlowna) {
      handler.nativeElement.classList.remove('offer__appear');
    }
  }

}
